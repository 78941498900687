import './App.css';
import logoWithoutName from './logoWithoutName.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logoWithoutName} className="App-logo" alt="logo" />
        <p className='App-logo-text'>
          AL JABARUT
        </p>
        {/* <p style={{
          fontFamily: 'serif', fontSize: '0.5em',
          position: 'absolute',
          bottom: '0.8vh'
        }}>
          Elevating Muslims Power Worldwide
        </p> */}
      </header >
    </div >
  );
}

export default App;
